 <template>
  <rb-drop-down
    :is-open="dropDownOpen"
    align="right"
    @close="toggleDropDown"
  >
    <div slot="toggler">
      <button
        class="action with-icon"
        @click="toggleDropDown"
      >
        <i class="material-icons">archive</i>
        <span>{{ rfpId && side === 'BUYER'? 'Reports and ': '' }}Exports</span>
      </button>
    </div>
    <div
      slot="dropdown"
      :class="$style.dropdownContainer"
      @click="toggleDropDown"
    >
      <template v-if="rfpId && side === 'BUYER'">
        <div
          :class="$style.title"
        >
          Reports
        </div>
        <button
          class="rbButtonMenuItem"
          @click="negotiatedSavings()"
        >
          Negotiated Savings
        </button>
        <div
          :class="$style.title"
        >
          Exports
        </div>
      </template>
      <button
        class="rbButtonMenuItem"
        @click="exportBidManagerToExcel()"
      >
        Bid Manager
      </button>
      <button
        v-if="rfpId"
        class="rbButtonMenuItem"
        @click="exportTravelDestinationsToExcel()"
      >
        Travel Destinations
      </button>
      <button
        class="rbButtonMenuItem"
        @click="exportGbtaToExcel()"
      >
        GBTA (xlsx)
      </button>
      <button
        class="rbButtonMenuItem"
        @click="exportGbtaToCsv"
      >
        GBTA (csv)
      </button>
      <button
        class="rbButtonMenuItem"
        @click="exportGbtaByStatusToCsv"
      >
        GBTA: with Status (csv)
      </button>
      <button
        class="rbButtonMenuItem"
        @click="exportGbtaLastOfferByStatusToCsv"
      >
         GBTA: Last Offer with Status (csv)
      </button>
      <button
        v-if="selectionContainsFinalAgreements"
        class="rbButtonMenuItem"
        @click="exportFinalAgreementsToExcel()"
      >
        Final Agreements (xlsx)
      </button>
      <button
        v-if="selectionContainsFinalAgreements"
        class="rbButtonMenuItem"
        @click="exportFinalAgreementsWithStatusToExcel()"
      >
        Final Agreements with Status (xlsx)
      </button>
      <button
        class="rbButtonMenuItem"
        @click="exportToExcel('HOTEL_RFP:GBTA_PRDS_NEW:EXCEL')"
      >
        PRDS (xlsx)
      </button>
      <button
        class="rbButtonMenuItem"
        @click="exportToExcel('HOTEL_RFP:GBTA_CONCUR-SABRE:EXCEL')"
      >
        Concur - SABRE (xlsx)
      </button>
      <button
        class="rbButtonMenuItem"
        @click="exportToExcel('HOTEL_RFP:GBTA_CONCUR-APOLLO:EXCEL')"
      >
        Concur - APOLLO (xlsx)
      </button>
      <button
        class="rbButtonMenuItem"
        @click="exportToExcel('HOTEL_RFP:GBTA_CONCUR-AMADEUS:EXCEL')"
      >
        Concur - AMADEUS (xlsx)
      </button>
      <button
        class="rbButtonMenuItem"
        @click="exportToExcel('HOTEL_RFP:GBTA_CONCUR-WORLDSPAN:EXCEL')"
      >
        Concur - WORLDSPAN (xlsx)
      </button>
<!--      <button-->
<!--        class="rbButtonMenuItem"-->
<!--        @click="exportToFile('HOTEL_RFP:GBTA_HARP:EXCEL')"-->
<!--      >-->
<!--        Harp (xlsx)-->
<!--      </button>-->
      <button
        class="rbButtonMenuItem"
        @click="exportToFile('HOTEL_RFP:GBTA_HARP:CSV')"
      >
        Harp (csv)
      </button>
      <button
        class="rbButtonMenuItem"
        @click="exportToExcel('HOTEL_RFP:GBTA_TRVL:EXCEL')"
      >
        Travel Inc (xlsx)
      </button>
      <button
        class="rbButtonMenuItem"
        @click="exportToExcel('HOTEL_RFP:GBTA_TRVL_2023:EXCEL')"
      >
        Travel Inc 2023 (xlsx)
      </button>
      <button
        class="rbButtonMenuItem"
        @click="exportToExcel('HOTEL_RFP:GBTA_CONCUR-WORLDSPAN_TRVLINC:EXCEL')"
      >
        Travel Inc - Concur 2023 (xlsx)
      </button>
      <button
        class="rbButtonMenuItem"
        @click="exportToExcel('HOTEL_RFP:GBTA_SABRE:EXCEL')"
      >
        Sabre GetThere (xlsx)
      </button>
      <button
        class="rbButtonMenuItem"
        @click="exportToExcel('HOTEL_RFP:GBTA_SABRE:SABRE:EXCEL')"
      >
        Sabre GetThere (Sabre GDS codes)
      </button>
      <button
        class="rbButtonMenuItem"
        @click="exportToExcel('HOTEL_RFP:GBTA_SABRE:APOLLO:EXCEL')"
      >
        Sabre GetThere (Apollo GDS codes)
      </button>
      <button
        class="rbButtonMenuItem"
        @click="exportToExcel('HOTEL_RFP:GBTA_SABRE:WORLDSPAN:EXCEL')"
      >
        Sabre GetThere (Worldspan GDS codes)
      </button>
      <button
        class="rbButtonMenuItem"
        @click="exportToExcel('HOTEL_RFP:GBTA_SABRE:AMADEUS:EXCEL')"
      >
        Sabre GetThere (Amadeus GDS codes)
      </button>
      <button
        v-if="canExportNegotiationRequests"
        class="rbButtonMenuItem"
        @click="exportNegotiationRequestsWithHistory()"
      >
        Export Negotiation Requests with History
      </button>
      <button
        v-if="canExportNegotiationRequests"
        class="rbButtonMenuItem"
        @click="exportNegotiationRequestsOnly()"
      >
        Export Negotiation Requests Only
      </button>
    </div>
  </rb-drop-down>
</template>

<script>
  import RbDropDown from 'vRoot/_core/RbDropdown.vue';
  import {mainAPI, notificationUtils, $state} from 'root/angular-injector-provider';
  import {NEGOTIATED_SAVINGS_REPORT} from 'root/states';
  export default {
    name: 'RbvHotelRfpBidManagerExport',
    components: {RbDropDown},
    props: {
      view: {
        type: Object,
        required: true
      },
      rfpId: {type: String, default: ''},
      side: {type: String, default: ''}
    },
    data: function () {
      return {
        dropDownOpen: false,
      }
    },
    computed: {
      selectionContainsFinalAgreements() {
        return this.view.bids && this.view.bids.find(b => b.state.status === 'FINAL_AGREEMENT')
      },
      canExportNegotiationRequests() {
        const bids = this.view.bids;
        if(!bids || this.side !== 'SUPPLIER' || !bids.length) return false;
        const aBid = bids[0],
          rfpId = aBid.rfpId;
        return aBid.hotelRfpType === 'CHAIN' && bids.every(b => b.rfpId === rfpId) && bids.some(b => b.state.status === 'NEGOTIATION_SENT');
      }
    },
    methods: {
      getBidsIds () { return this.view.bids.map(b => b._id) },
      export ( exportFn ) { return notificationUtils().onLoadWithNotification( exportFn ) },
      exportFinalAgreementsToExcel () {
        this.export( () => mainAPI().hotelRfpExportFinalAgreementsToExcel(this.getBidsIds()) )
      },
      exportFinalAgreementsWithStatusToExcel () {
        this.export( () => mainAPI().hotelRfpExportFinalAgreementsWithStatusToExcel(this.getBidsIds()) )
      },
      exportGbtaToExcel () {
        this.export( () => mainAPI().hotelRfpExportGbtaToExcel(this.getBidsIds()) )
      },
      exportBidManagerToExcel () {
        this.export( () => mainAPI().hotelRfpExportBidManagerToExcel(this.getBidsIds(), this.view.columns) )
      },
      exportGbtaToCsv() {
        this.export( () => mainAPI().hotelRfpExportGbtaToCsv(this.getBidsIds()) )
      },
      exportGbtaByStatusToCsv() {
        this.export(() =>  mainAPI().hotelRfpExportGbtaWithStatusToCsv(this.getBidsIds()) )
      },
      exportGbtaLastOfferByStatusToCsv() {
        this.export(() =>  mainAPI().hotelRfpExportGbtaLastOfferWithStatusToCsv(this.getBidsIds()) )
      },
      exportNegotiationRequestsWithHistory() {
        const bidsIds = this.view.bids.filter(b => b.state.status === 'NEGOTIATION_SENT').map(b => b._id);
        const name = `Negotiation Request from ${this.view.bids[0].rfp.specifications.name} with history`;
        this.export(() =>  mainAPI().exportNegotiationRequestsWithHistory(bidsIds, name))
      },
      exportNegotiationRequestsOnly() {
        const bidsIds = this.view.bids.filter(b => b.state.status === 'NEGOTIATION_SENT').map(b => b._id);
        const name = `Negotiation Request from ${this.view.bids[0].rfp.specifications.name}`;
        this.export(() =>  mainAPI().exportNegotiationRequestsOnly(bidsIds, name))
      },
      exportTravelDestinationsToExcel(){
        this.export(() => mainAPI().hotelRfpExportTravelDestinations(this.rfpId))
      },
      exportToExcel(key) {
        this.export(() => mainAPI().exportBidManagerToExcel(this.getBidsIds(), key))
      },
      exportToFile(key) {
        this.export(() => mainAPI().exportBidManagerToFile(this.getBidsIds(), key))
      },
      toggleDropDown() {
        if (this.dropDownOpen) {
          this.dropDownOpen = false
        } else {
          this.$emit('refreshView')
          this.dropDownOpen = true
        }
      },
      negotiatedSavings() {
        $state().inNew(NEGOTIATED_SAVINGS_REPORT, {rfpId: this.rfpId})
      },
    }
  }
</script>

<style lang="stylus" module="">
  .dropdownContainer {
    background: #FFFFFF;
    border: 1px solid #CFD8DC;
    margin-top: 4px;
    border-radius: 3px;
    box-shadow : 0 0 5px 1px rgba(0, 0, 0, 0.18);
    max-height: 70vh;
    overflow: auto;
  }

  .title {
    background: #ECEFF1;
    padding 5px;
    font-size 14px
  }
</style>

