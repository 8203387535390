export const RATE_LOADING_ALLOWED_ACCOUNTS = [
  '61802c489fbe47096ec47a09', // Kontoor Brands
  '65525c8eb9a70212c99735bc', // BAE Systems
  '625d86116269e66236a6c39c', // Booz Allen Hamilton
  '5d9ef25f317d1e6cf6158e38', // Options Travel
  '654a758920391e097af8825f', // Haleon
  '6398daef3e041128cdf07833', // TravelINC
  '6453cf071268b84929e86ac0', // CostCo
  // '5d5b0b819fbe470dc7435b10', // KesselRun Consulting
];

export default function isRateLoadingAvailableToUser(view){
  if(view.side !== 'BUYER') return true;

  return !RATE_LOADING_ALLOWED_ACCOUNTS.length || RATE_LOADING_ALLOWED_ACCOUNTS.includes(view.ownerId);
}
